<template>
    <div class="wdn-settled-main">
        <div class="wd-settled">
            <div class="settled-header">
                <p>入驻申请</p>
            </div>
            <el-form ref="myForm" :label-position="'left'" :rules="rules" label-width="162px" :model="formData"
                @submit.native.prevent>
                <el-form-item label="企业名称：" prop="name">
                    <el-input :maxlength="50" v-model="formData.name" size="small" placeholder="请输入企业名称："></el-input>
                </el-form-item>
                <el-form-item label="企业类型：" prop="enterType">
                    <el-select style="width: 520px" v-model="formData.enterType" placeholder="请选择企业类型">
                        <el-option v-for="item in industryArr" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系人：" prop="userName">
                    <el-input v-model="formData.userName" size="small" :maxlength="20" placeholder="请输入联系人姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：" prop="phone" class="hasTip" style="margin-bottom: 8px">
                    <el-input v-model.trim="formData.phone" size="small" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <div class="authCode formItemTip">
                    <el-input v-model="authCode" maxlength="6" size="small" placeholder="请输入验证码"
                        style="width: 412px"></el-input>
                    <el-button v-if="Countdown" class="getcodeBtn getcodeBtnGray" type="primary" size="small"
                        @click="getAuthCode">{{ Countdown + "s" }}</el-button>
                    <el-button v-else-if="isFirstGetAuthcode" class="getcodeBtn" type="primary" size="small"
                        @click="getAuthCode">获取验证码</el-button>
                    <el-button v-else class="getcodeBtn" type="primary" size="small" @click="getAuthCode">重新获取</el-button>
                </div>

                <el-form-item label="所属上级：" class="needRequred" style="margin-bottom: 10px">
                    <el-row type="flex" justify="space-between">
                        <el-col class="selectItem">
                            <el-form-item prop="city" style="margin-bottom: 0">
                                <el-select ref="selectCity" v-model="formData.city" size="small" placeholder="请选择乡镇街道"
                                    @change="handleCityChange">
                                    <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col class="selectItem">
                            <!-- <el-form-item style="margin-bottom: 0">
                                <el-select v-model="formData.district" :disabled="!formData.city || disabledSelect"
                                    @change="handleDistrictChange" clearable="" size="small" placeholder="请选择乡镇街道">
                                    <el-option v-for="item in districtList" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item> -->
                        </el-col>
                        <el-col class="selectItem">
                            <!-- <el-form-item style="margin-bottom: 0">
                                <el-select v-model="formData.street" :disabled="!formData.district || disabledSelect"
                                    size="small" clearable="" placeholder="请选择乡镇街道园区">
                                    <el-option v-for="item in streetList" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item> -->
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="详细地址：" prop="addressDetail">
                    <el-input v-model="formData.addressDetail" size="small" :maxlength="50"
                        placeholder="请输入详细地址"></el-input>
                </el-form-item>

                <el-form-item label="统一社会信用代码/注册码：" prop="organCode" key="organCode">
                    <el-input v-model="formData.organCode" size="small" placeholder="请输入社会统一信用代码/注册码"></el-input>
                </el-form-item>
                <el-form-item label="营业执照副本：">
                    <el-upload v-loading="licenseImageUploadLoading" class="avatar-uploader" :action="action"
                        :headers="headers" accept="image/jpg, image/jpeg, image/png" :show-file-list="false"
                        :on-success="licenseImageSuccess" :before-upload="beforeLicenseImageUpload" :on-error="() => {
                            licenseImageUploadLoading = false;
                        }
                            ">
                        <img v-if="formData.businessLicenseImage" :src="formData.businessLicenseImage" class="avatar" />
                        <div v-else>
                            <i class="el-icon-plus avatar-uploader-icon"></i>
                            <p class="uploadTip">点击上传</p>
                        </div>
                        <div slot="tip" class="el-upload__tip">
                            营业执照扫描件图片后上传，支持jpg、png格式，图片大小不超过5M。
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div class="settled-footer">
                <el-button type="primary" size="small" class="submitBtn" @click="submit">提交申请</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import config from "@/config/url";
import { organCodeValidatorysx } from "./../../../utils/validator";
export default {
    name: "formDialog",
    data() {
        const accountValidator = (rule, value, callback) => {
            const isPhone =
            /^1[3-9]\d{9}$/;
            if (!isPhone.test(value)) {
                callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return {
            licenseImageUploadLoading: false,
            action: config.API + "/resource/v1/resource/uploadPicture",
            headers: {
                token: localStorage.getItem("token"),
            },
            industryArr: [
                {
                    name: '石油液化气企业',
                    value: 67,
                },
                {
                    name: '天然气企业',
                    value: 68,
                },
                {
                    name: '石油液化气个体工商户',
                    value: 69,
                },
                {
                    name: '天然气个体工商户',
                    value: 70,
                }
            ],
            authCode: "",
            cityList: [],
            districtList: [],
            streetList: [],
            disabledSelect: false,//选择省直属时，灰置后面选择框
            formData: {
                name: "",
                enterType: "",
                userName: "",
                phone: "",
                targetBranchId: "", //注册到目标机构下
                addressDetail: "",
                city: "",
                district: "",
                street: "",
                businessLicenseImage: "",
                organCode: "",
            },

            rules: {
                name: { required: true, message: "请输入单位名称", trigger: "blur" },
                enterType: {
                    required: true,
                    message: "请选择企业类型",
                    trigger: "change",
                },
                userName: {
                    required: true,
                    message: "请输入联系人姓名",
                    trigger: "blur",
                },
                city: { required: true, message: "请选择乡镇街道", trigger: "change" },
                addressDetail: { required: true, message: "请输入详细地址", trigger: "blur" },
                organCode: [
                    {
                        required: true,
                        message: "请输入社会统一信用代码/注册码",
                        trigger: "blur",
                    },
                    { validator: organCodeValidatorysx, trigger: "blur" },
                ],
                phone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { validator: accountValidator, trigger: "blur" },
                ],
            },
            isFirstGetAuthcode: true,
            Countdown: 0,

        };
    },
    mounted() {
        // 获取省市区机构
        this.findRegion(1, null);
    },
    methods: {
        findRegion(level, parentId) {
            let params = {
                dataType: 4,
                parentId,
            };
            this.$api.commonBranch.findRegion(params).then((res) => {
                if (res.success && res.data) {
                    if (level == 1) {
                        this.cityList = res.data;
                    } else if (level == 2) {
                        this.districtList = res.data;
                    } else if (level == 3) {
                        this.streetList = res.data;
                    }
                }
            });
        },
        getAuthCode() {
            this.$refs.myForm.validateField("phone", (error) => {
                if (!error) {
                    const data = { account: this.formData.phone, type: 2 };
                    if (this.Countdown > 0) return;
                    this.Countdown = 60;
                    this.setCountdown();
                    this.$api.usercenter.getAuthCodePublic({ data });
                    this.isFirstGetAuthcode = false;
                }
            });
        },
        setCountdown() {
            this.CountdownInterval = setInterval(() => {
                this.Countdown--;
                if (this.Countdown <= 0) {
                    clearInterval(this.CountdownInterval);
                }
            }, 1000);
        },
        beforeLicenseImageUpload(file) {
            if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type)) {
                this.$message.error("请上传jpg、png格式的图片");
                return false;
            }
            if (file.size / 1024 / 1024 > 5) {
                this.$message.error("图片大小不能超过5M");
                return false;
            }
            this.licenseImageUploadLoading = true;
        },
        licenseImageSuccess(res) {
            this.formData.businessLicenseImage = res.data;
            this.licenseImageUploadLoading = false;
            // this.$refs.myForm.clearValidate("businessLicenseImage");
        },
        handleCityChange(id) {
            this.$nextTick(() => {
                if (this.$refs.selectCity.selectedLabel === '省直属') {
                    this.disabledSelect = true
                } else {
                    this.disabledSelect = false
                }
            })
            this.formData.district = "";
            this.formData.street = "";
            this.districtList = []
            this.streetList = []
            this.findRegion(2, id);
        },
        handleDistrictChange(id) {
            this.formData.street = "";
            this.streetList = []
            this.findRegion(3, id);
        },
        async submit() {
            this.$message.error("本次培训已结束");
            return
        //     try {
        //         await this.$refs.myForm.validate();
        //     } catch (error) {
        //         return;
        //     }
        //     const params = { dataType: 4 }
        //     let data = { ...this.formData };
        //     data.targetBranchId = data.street || data.district || data.city; //入驻到目标机构
        //     data.authCode = this.authCode;
        //     this.$api.usercenter.saveyjysxBranchApply(data, params).then((res) => {
        //         if (res.success) {
        //             this.$alert("提交成功", "即将跳转企业管理后台", {
        //                 confirmButtonText: "确定",
        //                 center: true,
        //                 customClass: "messageBox",
        //                 confirmButtonClass: "dialog_confirmBtn",
        //                 callback: () => {
        //                     this.$refs.myForm.resetFields();
        //                     this.authCode = "";
        //                     // 所属上级
        //                     this.formData.district = "";
        //                     this.formData.street = "";
        //                     this.formData.businessLicenseImage = "";
        //                     let envArr = ['fat', 'uat']
        //                     let env = 'prod'
        //                     envArr.forEach((d) => {
        //                         if (window.location.hostname.includes(d)) {
        //                             env = d
        //                         }
        //                     })

        //                     let yingjiUrl = {
        //                         fat: "https://ysxyj-admin.fat.wdeduc.com/",
        //                         uat: "https://ysxyj-admin.uat.wdeduc.com/",
        //                         prod: "https://ysxyj-admin.wdeduc.com/",
        //                     }
        //                     let openUrl  = yingjiUrl[env]
        //                     window.open(openUrl, '_blank')
        //                 },
        //             });
        //         }
        //     });
        },
    },
};
</script>
<style lang="stylus" scoped>
  @import '../css/settled.styl'
  .wd-settled {
    padding: 32px 48px 318px;
  }
  </style>
  